import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 164px;
  height: 48px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.textM};
  font-weight: ${({ theme }) => theme.medium};
  color: #000;
  border: 2px solid #000;
  border-radius: 33px;
  text-decoration: none;
`;

const SVG = styled.span`
  margin-left: 30px;
`;

const Button = ({ link }) => {
  return (
    <StyledButton to={link} target="_blank" className="button">
      Przejdź
      <SVG>
        <svg
          width="32"
          height="12"
          viewBox="0 0 32 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.1833 6.77136C31.4762 6.47847 31.4762 6.00359 31.1833 5.7107L26.4103 0.937729C26.1174 0.644836 25.6425 0.644836 25.3496 0.937729C25.0567 1.23062 25.0567 1.7055 25.3496 1.99839L29.5923 6.24103L25.3496 10.4837C25.0567 10.7766 25.0567 11.2514 25.3496 11.5443C25.6425 11.8372 26.1174 11.8372 26.4103 11.5443L31.1833 6.77136ZM0.0231933 6.99103L30.6529 6.99103L30.6529 5.49103L0.0231934 5.49103L0.0231933 6.99103Z"
            fill="black"
          />
        </svg>
      </SVG>
    </StyledButton>
  );
};

export default Button;
