import React, { useState, useEffect } from 'react';
import Main from '../templates/Main';
import styled from 'styled-components';
import MojaProwizjaIMG from '../images/mojaprowizja.jpg';
import SkredytujIMG from '../images/skredytuj.jpg';
import WotiIMG from '../images/woti.jpg';
import MainIMG from '../images/mainbg.jpg';
import Text from '../components/Text';
import Headline from '../components/Headline';
import Button from '../components/Button';
import Logo from '../components/Logo';
import MojaProwizjaLogo from '../images/mojaprowizja-logo.png';
import SkredytujLogo from '../images/skredytuj-logo.png';
import WotiLogo from '../images/woti-logo.png';

const Section = styled.section`
  height: 100vh;
  padding-top: 80px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    display: flex;
    padding-top: 0;

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url(${MainIMG});
      background-size: cover;
      background-position: center;
    }
  }
`;

const Column = styled.div`
  width: 100%;
  height: 443px;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 25px;
  transition: background-color 1s ${({ theme }) => theme.easing1};

  @media (max-width: ${({ theme }) => theme.laptop}) {
    .bg-image {
      opacity: 1;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);

      * {
        color: white;
      }

      .button {
        border: 2px solid #fff;

        svg path {
          fill: #fff;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    /* width: 33.33%; */
    width: 50%;
    height: 100%;
    position: unset;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      .bg-image {
        opacity: 1;
      }
      * {
        color: white;
      }

      .button {
        border: 2px solid #fff;

        svg path {
          fill: #fff;
        }
      }

      .logo {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0 50px;
  }
  @media (min-width: ${({ theme }) => theme.lgDesktop}) {
    padding: 0 80px;
  }
`;

const BackgroundImageFirst = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${MojaProwizjaIMG});
  background-size: cover;
  opacity: 0;
  z-index: -1;
  transition: all 1s ${({ theme }) => theme.easing1};
  @media (min-width: ${({ theme }) => theme.laptop}) {
  }
`;

const BackgroundImageSecond = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${SkredytujIMG});
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: all 1s ${({ theme }) => theme.easing1};
  @media (min-width: ${({ theme }) => theme.laptop}) {
  }
`;

const BackgroundImageThird = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${WotiIMG});
  background-size: cover;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transition: all 1s ${({ theme }) => theme.easing1};
  @media (min-width: ${({ theme }) => theme.laptop}) {
  }
`;

const Content = styled.div``;

const IndexPage = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    const handler = () => setWidth(window.innerWidth);

    window.addEventListener(`resize`, handler);
    return () => window.removeEventListener(`resize`, handler);
  }, []);

  return (
    <Main>
      <Section className='active'>
        <Column className='isFirst'>
          <Content>
            <Headline
              data-aos='headline-fadeup'
              data-aos-anchor='.isFirst'
              data-aos-delay='200'
            >
              Odzyskaj swoją prowizję już dziś!
            </Headline>
            <Text
              data-aos='headline-fadeup'
              data-aos-anchor='.isFirst'
              data-aos-delay='400'
            >
              mojaprowizja.pl
            </Text>
            <Logo image={MojaProwizjaLogo} />
            <Button link='https://mojaprowizja.pl' />
          </Content>
          <BackgroundImageFirst className='bg-image'></BackgroundImageFirst>
        </Column>

        {/*  */}

        {/* <Column className="isSecond">
          <Content>
            {width > 1200 ? (
              <>
                <Headline
                  data-aos="headline-fadeup"
                  data-aos-anchor=".isSecond"
                  data-aos-delay="600"
                >
                  Pozbądź się pożyczek pozabankowych
                </Headline>
                <Text
                  data-aos="headline-fadeup"
                  data-aos-anchor=".isSecond"
                  data-aos-delay="800"
                >
                  skredytuj.pl
                </Text>
              </>
            ) : (
              <>
                <Headline
                  data-aos="headline-fadeup"
                  data-aos-anchor=".isSecond"
                  data-aos-delay="200"
                >
                  Pozbądź się pożyczek pozabankowych
                </Headline>
                <Text
                  data-aos="headline-fadeup"
                  data-aos-anchor=".isSecond"
                  data-aos-delay="400"
                >
                  skredytuj.pl
                </Text>
              </>
            )}
            <Logo image={SkredytujLogo} />
            <Button link="https://skredytuj.pl" />
          </Content>
          <BackgroundImageSecond className="bg-image"></BackgroundImageSecond>
        </Column> */}

        {/*  */}

        <Column className='isThird'>
          <Content>
            <Headline
              data-aos='headline-fadeup'
              data-aos-anchor='.isThird'
              data-aos-delay={width > 1200 ? `1000` : `200`}
            >
              Spłać parabanki pożyczką oddłużeniową niewidoczną w BIK
            </Headline>
            <Text
              data-aos='headline-fadeup'
              data-aos-anchor='.isThird'
              data-aos-delay={width > 1200 ? `1200` : `400`}
            >
              woti.pl
            </Text>
            <Logo image={WotiLogo} />
            <Button link='https://woti.pl' />
          </Content>
          <BackgroundImageThird className='bg-image'></BackgroundImageThird>
        </Column>
      </Section>
    </Main>
  );
};

export default IndexPage;
