import React from "react";
import styled from "styled-components";
import WrbLogo from "../images/wrblogo.png";

const Wrapper = styled.header`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.5);

  @media (min-width: ${({ theme }) => theme.laptop}) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  width: 50px;
  img {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    display: none;
  }
`;

const Header = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <img src={WrbLogo} alt="Logo WRB Group" />
      </LogoWrapper>
    </Wrapper>
  );
};

export default Header;
