import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { theme } from "../theme/theme";
import GlobalStyles from "../theme/GlobalStyles";
import Header from "../components/Header";
import AOS from "aos";
import "aos/dist/aos.css";

const Main = ({ children }) => {
  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    const handler = () => setWidth(window.innerWidth);

    window.addEventListener(`resize`, handler);
    return () => window.removeEventListener(`resize`, handler);
  }, []);

  if (width !== 0) {
    AOS.init({
      duration: 1200,
      easing: [0.22, 1, 0.36, 1],
      once: true,
      offset: 200,
      anchorPlacement: "top-bottom",
    });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WRB Group Sp. z o.o.</title>
        <meta
          name="description"
          content="Zajmujemy się odzyskiwaniem prowizji, pomocą w pozbyciu się pożyczek pozabankowych oraz spłacaniem parabanków pożyczką oddłużeniową niewidoczną w BIK"
        />
        <meta property="og:url" content="https://wrbgroup.pl" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="WRB Group" />
        <meta property="og:image" content="https://wrbgroup.pl/ogimage.png" />
        <meta
          property="og:description"
          content="Zajmujemy się odzyskiwaniem prowizji, pomocą w pozbyciu się pożyczek pozabankowych oraz spłacaniem parabanków pożyczką oddłużeniową niewidoczną w BIK"
        />
        <meta property="og:site_name" content="WRB Group" />
        <meta property="og:locale" content="pl_PL" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header />
        <main>{children}</main>
      </ThemeProvider>
    </>
  );
};

export default Main;
