import styled from "styled-components";

export default styled.h2`
  font-size: ${({ theme }) => theme.headlineS};
  font-weight: ${({ theme }) => theme.bold};
  margin-bottom: 30px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.headlineM};
    max-width: 360px;
    height: 130px;
    margin-bottom: 20px;
  }
`;
