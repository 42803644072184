import React from "react";
import styled from "styled-components";

const LogoWrapper = styled.div`
  height: 66px;
  margin-bottom: 40px;
  transition: all 0.4s ${({ theme }) => theme.easing1};

  @media (min-width: ${({ theme }) => theme.laptop}) {
    transform: translateY(30px);
    opacity: 0;
  }
`;

const Logo = ({ image }) => {
  return (
    <LogoWrapper className="logo">
      <img src={image} />
    </LogoWrapper>
  );
};

export default Logo;
