export const theme = {
  primary: "#232A3E",
  dark: "#21201D",
  white: "#ffffff",
  black: "#000000",
  yellow: "#FFB703",

  fontFamily: "'Satoshi', sans-serif",
  light: "200",
  medium: "400",
  bold: "600",

  textS: "14px",
  textM: "16px",
  textL: "18px",
  textXL: "20px",
  headlineS: "24px",
  headlineM: "32px",

  easing1: "cubic-bezier(0.22, 1, 0.36, 1)",

  phone: "576px",
  tablet: "768px",
  smLaptop: "992px",
  laptop: "1200px",
  desktop: "1400px",
  lgDesktop: "1920px",
};
