import { createGlobalStyle } from "styled-components";
import "./typography.css";

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: "Satoshi", sans-serif;
  }

  [data-aos="headline-fadeup"] {
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1;
  }
}
`;

export default GlobalStyles;
